import { RouterProvider } from "react-router-dom";
import router from "./router";
import { SocketProvider } from "./lib/SocketIo";
import { HelmetProvider } from "react-helmet-async";
import { HeGameProvider } from "./lib/GameProvider";

export default function App() {
  return (
    <HelmetProvider>
      <SocketProvider url="https://socket.myfonfun.com/myfunfon" options={{
        reconnect: true, transports: ['websocket'], upgrade: false
      }}>
        <HeGameProvider>
          <RouterProvider router={router}></RouterProvider>
        </HeGameProvider>
      </SocketProvider>
    </HelmetProvider>
  )
}
